body{
  background-color: #f1f1f1;
}

.page-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  height: 50px;
  background-color: #fff;
  width: 100%;
  box-shadow: 0px 1px 3px 0px #c8c8c8;
  .header-title{
    font-size: 1.5rem;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
  }

  .header-logo{
    height: 50px;
    width: 250px;
    background-image: url(/media/logo.jpg);
    background-size: 100%;
    background-repeat: no-repeat;
  }
}

.header-logo {
    /* width: 14vw;
     margin-top: 5px;
    margin-left: auto;*/
    margin: auto;
    order: 2;
    display: flex;
    /* color: #0099d7;*/
    color: black;
    /* font-weight: bold;
    font-size: 10px;*/
    font-size: 1rem;
    max-width: 250px;
}

.header-logo-left {
    margin-top: 5px;
    padding-left: 5px;
}

.header-logo-right {
    margin-top: 5px;
    padding-right: 5px;
}

.header-logo-img {
    width: 100px;
}

.inline-header {
    float: left;
    // display: flex;
    margin-right: 10px;
    font-size: 0.58rem;
}
.content {
    padding: 10px 30px;
}

.dash-stat-box 
{
  padding: 15px;
  border-radius: 5px;
  height: 180px;
  //aspect-ratio: 2;
  max-width: 500px;
  display: flex;
  flex-direction: column;

  .ds-title{
    flex: 40;
    font-size: 1.4rem;
    color:#fff;
    text-align: center;
  }
  .ds-body{
    flex: 60;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px;
    text-align: center;
    border-radius: 5px;
    overflow: hidden;
  }

  .ds-label{
    font-size: 1.4rem;
    color: #fff;
  }
  
  .ds-value{
    margin-top: -20px;
    text-align: right;
    font-size: 2.5rem;
    color: #fff;
  }
  .ds-divider{
    border-bottom: 1px solid #d4d4d4;
    content: ' ';
  }
  &.green{
    background-color: rgb(29, 126, 34);
  }
  &.darkblue{
    background-color: rgb(21, 87, 107);
  }
  &.blue{
    background-color: rgb(65, 136, 158);
  }
  &.orange{
    background-color: rgb(177, 119, 25);
  }
}

.fieldset{
  border: 1px solid #ccc;
  padding: 8px 15px;
  border-radius: 5px;
  background-color: #fff;
  margin-bottom: 15px;
  .fieldset-title{
    font-size: 1.5rem;
    color: #2d2dc1;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .fieldset-body{
  }
}

.form-label{
  color: #1616d3;
  margin-bottom:0.1rem;
}
.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}

.dash-status-contanter{
  display: flex;
  flex-direction: column;
  .dash-status-box{
    flex:1;
    margin-bottom: 10px;
    >div{
      height: 100%;
    }
  }
}


///////////
.auth-container{
  display: flex;
  align-items: center;
  justify-content: center;
  //height: 100vh;

  .auth-content{
    min-width: 400px;
    max-width: 1200px;
    border:1px solid #ccc;
    border-radius: 26px;
    box-shadow: 0 0 6px 2px #ccc;
  }

  .auth-desc{
    height: 100%;
    background-color: #38499d;
    padding: 30px;
    border-radius: 25px 0 0 25px;
  }

  .auth-form{
    
    padding: 50px 30px 50px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .login-head{
    text-align: center;
  }

}

////////

.navbar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px;
  background-color: #2472b1 !important;
  .nav-link{
    margin-right: 8px;
    color: #fff;
    &.nav-link-selected{
      background-color: #1616d3;
    }
  }
}

.form-container{
  .row{
    >div{
      margin-bottom: 15px;
    }
  }
}

#plzw {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0,0,0,.3);
  z-index: 99999;
}

#plzw .plzw-content {
  width: 308px;
  position: absolute;
  left: 39%;
  top: 37%;
}

#plzw .plzw-loader {
  color: #fff;
  text-align: center;
  margin-bottom: 10px;
}